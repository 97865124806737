import React from 'react';
import { Spin } from '@sis-lab/web-ui-components'
import './LoadingOverlay.scss';

const LoadingOverlay = ({ active }: { active: boolean }) => (
  <div
    className='loading-overlay'
    style={{
      top: 0,
      zIndex: 99,
      width: '100%',
      height: '100%',
      display: 'flex',
      position: 'fixed',
      background: 'white',
      alignItems: 'center',
      transition: 'all 0.3s',
      justifyContent: 'center',
      visibility: active ? 'visible' : 'hidden',
      opacity: active ? 1 : 0,
    }}
  >
    <Spin spinning size='large' />
  </div>
);

export default LoadingOverlay;
