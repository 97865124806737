/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import sha256 from 'crypto-js/sha256';
import { useNavigate } from 'react-router-dom';
import { arrayBufferToWordArray } from 'utils';
import { validateDocumentService } from 'services/documents';

import {
  Input, Card, Button, useForm, Upload, Icon, useWindowSize, WindowSize, breakpoints, Divider
} from '@sis-lab/web-ui-components';

import { message } from 'antd';

import styles from './ValidatorPage.module.scss';

const ValidatorPage = () => {
  const form = useForm();
  const navigate = useNavigate();
  const windowSize: WindowSize = useWindowSize();
  const [loading, setLoading] = useState(false);

  const validateDocument = async (hash: string) => {
    const key = Math.random();
    message.loading({
      key,
      content: ' Checking your document...',
      duration: 0,
    });
    setLoading(true);
    form.setValue('hash', hash);

    try {
      const validationResults = await validateDocumentService(hash);
      localStorage.setItem('validationResults', JSON.stringify(validationResults))

      message.success({ key, content: ' Redirecting you...', duration: 1 });
      setTimeout(() => {
        setLoading(false);
        navigate(`validate/${hash}`);
      }, 1000);
    } catch (err: any) {
      setLoading(false);
      message.error({ key, content: ' Document not found...', duration: 1.5 });
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.body}>
        <Card
          size='medium'
          className={styles.card}
          footerActions={
            [
              <Button
                loading={loading}
                disabled={!form.watch('hash')}
                htmlType='submit'
                type='primary'
                children='Validate'
                onClick={form.handleSubmit((data) => validateDocument(data.hash))}
              />,
            ]
          }
        >
          <div className={styles.description}>
            <Icon className={styles.descrIcon} name='info_outline' />
            <p>
              Check the legitimacy of a document signed using CorpoSign.
              We will check the legitimacy of the signature and see
              whether the document has been tampered with after it was signed.
            </p>
          </div>
            <Upload
              name='file'
              size='small'
              title='Document'
              disabled={loading}
              className={styles.upload}
              icon={<Icon className={styles.icon} name='find' />}
              onUpload={async (file: any) => {
                const hash: string = await new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(file);
                  reader.onload = () => {
                    resolve(sha256(arrayBufferToWordArray(reader.result)).toString());
                  };
                });
                await validateDocument(hash);
                return false;
              }}
              text={
                windowSize.width < breakpoints.tabletSmall
                  ? 'Tap here to upload'
                  : 'Upload or drop the original document'
              }
              helpText='Supported formats: PDF, DOC, DOCX. Maximum size 20mb.'
            />
            <Divider text='OR' size='small' className={styles.divider} />
            <Input
              formHook={form}
              disabled={loading}
              name='hash'
              title='Search by Fingerprint'
            />
        </Card>
      </div>
    </div>
  );
};

export default ValidatorPage;
