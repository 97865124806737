/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '@sis-lab/web-ui-components';
import corposignLogo from 'images/corposign-logo.svg';
import styles from './CorpoHeader.module.scss';

const CorpoHeader = () => {
  const navigate = useNavigate();

  return (
    <Header
      onTitleClick={() => navigate('/')}
      logo={corposignLogo}
      title={<h1 className={styles.title}>CorpoSign</h1>}
    />
  );
};

export default CorpoHeader;
