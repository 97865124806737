import CryptoJS from 'crypto-js';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const transformDate = (date: string | undefined) => (date ? `${new Date(date).toLocaleDateString('lt-lt')} ${new Date(date).toLocaleTimeString('lt-lt')}` : '');

export const arrayBufferToWordArray = (ab: any) => {
  const i8a = new Uint8Array(ab);
  const a = [];
  for (let i = 0; i < i8a.length; i += 4) {
    a.push(
      (i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3],
    );
  }
  return CryptoJS.lib.WordArray.create(a, i8a.length);
};

export const getCurrentTimeDate = () => {
  const current = new Date();

  const date = `0${current.getDate()}`.slice(-2);
  const month = `0${current.getMonth() + 1}`.slice(-2);
  const year = current.getFullYear();

  const hours = `0${current.getHours()}`.slice(-2);
  const minutes = `0${current.getMinutes()}`.slice(-2);
  const seconds = `0${current.getSeconds()}`.slice(-2);

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const downloadBlobFile = (fileData: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(fileData);
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', fileName);
  a.click();
};
