import { Paragraph } from '@sis-lab/web-ui-components';
import React from 'react';
import { IDocumentValidation } from 'types';
import ParticipantCard from './components/ParticipantCard/ParticipantCard';

interface Props {
  validationResult: IDocumentValidation
}

const PartipantTab = ({ validationResult }: Props) => (
  <>
    {validationResult.signatures.map((signature) => <ParticipantCard.Active signature={signature} />)}
    {!validationResult.signatures.length && (
      <Paragraph style={{ textAlign: 'center' }}>No signatures</Paragraph>
    )}
  </>
);

export default PartipantTab;
