import React from 'react';
import { Button, Label } from '@sis-lab/web-ui-components';
import { useNavigate } from 'react-router-dom';

interface Props {
  error: string
}

const ErrorMessage = ({ error }: Props) => {
  const navigate = useNavigate();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Label>{error}</Label>
      <Button
        type='primary'
        onClick={() => navigate('/')}
      >
        Go back to Validator
      </Button>
    </div>
  );
};

export default ErrorMessage;
