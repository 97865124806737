import React from 'react';
import config from 'config';
import { Footer } from '@sis-lab/web-ui-components';
import CorpoHeader from './components/CorpoHeader/CorpoHeader';
import { transformDate } from 'utils';
import styles from './Layout.module.scss';

type Props = {
  children?: React.ReactNode
}

const Layout = ({ children }: Props) => {

  return (
    <div
      className={[
        styles.layout,
        styles.column,
      ].join(' ')}
    >
      <CorpoHeader/>
      <main>
        {children}
        <Footer className={styles.footer}>
          {'CorpoSign ©'}
          {' '}
          {new Date().getFullYear()}
          {' | '}
          { transformDate(config.projectCommitDate) }
          {' | '}
          { config.projectCommitShortSHA }
        </Footer>
      </main>
    </div>
  );
};

export default Layout;
