import React, { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Layout from 'components/Layout/Layout';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import ValidatorPage from './pages/ValidatorPage/ValidatorPage';
import DocumentPage from './pages/DocumentPage/DocumentPage';

import './App.scss';

function App() {
  const [rendering, setRendering] = useState(true);
  useEffect(() => {
    async function hideOverlay() {
      setTimeout(() => setRendering(false), 500)
    }
    hideOverlay()
  }, []);

  return (
    <BrowserRouter>
      <LoadingOverlay active={rendering} />
      <Layout>
        <Routes>
          <Route path='/' element={<ValidatorPage/>} />
          <Route path='/validate/:fingerprint' element={<DocumentPage/>} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
