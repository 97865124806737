/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { IDocumentValidation } from 'types';
import { getDocumentCertificateService, validateDocumentService } from 'services/documents';

import {
  Title,
  Button,
  Spin,
  Card,
  Form,
  useForm,
  Input,
  Divider,
  Tabs,
  Tab,
  Icon,
} from '@sis-lab/web-ui-components';

import { message } from 'antd';
import { HistoryTab, ErrorMessage, ParticipantTab } from './components';

import styles from './DocumentPage.module.scss';
import { downloadBlobFile, transformDate } from 'utils';

const DocumentPage = () => {
  let navigate = useNavigate();
  const form = useForm();
  const { fingerprint } = useParams();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [certificateLoading, setCertificateLoading] = useState(false);
  const [validationResult, setValidationResult] = useState<IDocumentValidation>();
  const storedValidationResults = localStorage.getItem('validationResults')
  const validationResults = storedValidationResults ? JSON.parse(storedValidationResults) : undefined
  
  const fetchDocument = async () => {
    // if (validationResults && validationResults.document.fingerprint === fingerprint) {
    //   setValidationResult(validationResults);
    // } else {
    setLoading(true);
    try {
      const validationResponse = await validateDocumentService(fingerprint!);
      setValidationResult(validationResponse);
    } catch (err: any) {
      setError(`${err.response.status}: ${err.response.data.message}`);
    } finally {
      setLoading(false);
    }
    // }
  };

  const fetchCertificate = async () => {
    if (!validationResult) return;
    setCertificateLoading(true);
    try {
      const [fileData, fileName] = await getDocumentCertificateService(validationResult.document.fingerprint);
      if (fileData && fileName) downloadBlobFile(fileData, fileName);
      else message.error('Some error occurred during download');
    } catch (err) {
      message.error("This document can't be downloaded now");
    }
    setCertificateLoading(false);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  return (
    <>
      {error && <div className={styles.loader}><ErrorMessage error={error} /></div>}
      {loading && <div className={styles.loader}><Spin size='large' /></div>}
      { validationResult && !loading &&  (
        <div className={styles.page}>
          <header className={styles.header}>
            <Title className={styles.title} seo='h1' styling='h4'>{validationResult?.document.title}</Title>
          </header>
<div className={styles.body}>
            <div className={styles.column}>
              <Card
                size='small'
                header={{ title: 'Document information' }}
              >
                <Form className={styles.docInfo} formHook={form} size='medium'>
                  <Input
                    disabled
                    name='created_at'
                    title='Created date'
                    defaultValue={transformDate(validationResult.document.created_at)}
                  />
                  <Input
                    disabled
                    name='creator'
                    title='Created by'
                    defaultValue={validationResult.document.owner.name}
                  />
                  <Input
                    disabled
                    name='format'
                    title='Format'
                    defaultValue={validationResult.document.format}
                  />
                  <Input
                    disabled
                    name='type'
                    title='Type'
                    defaultValue={validationResult.document.type}
                  />
                  <Input
                    disabled
                    name='creator'
                    title='Notes'
                    defaultValue={validationResult.document.notes || '—'}
                  />
                </Form>
              </Card>
              <Card header={{ title: 'Document validity' }} size='small'>
                <Form formHook={form} size='medium'>
                  <Input
                    disabled
                    name='documentID'
                    title='Document ID'
                    defaultValue={validationResult.document.id}
                  />
                  <Input
                    disabled
                    name='fingerprint'
                    title='Fingerprint'
                    defaultValue={validationResult.document.fingerprint}
                  />
                  <Input
                    disabled
                    name='url'
                    title='Storage URL'
                    defaultValue={validationResult.document.url || '—'}
                  />
                  <Input
                    disabled
                    name='certificateID'
                    title='Owners Certificate ID'
                    defaultValue={validationResult.document.owner.certificate_id}
                  />
                </Form>
              </Card>
            </div>

            <div className={styles.column}>
              <Card size='small' className={styles.tabCard}>
                <Tabs size='medium' tabsClassName={styles.tabHeader} bodyClassName={styles.tabBody}>
                  <Tab
                    label='Signees'
                    suffix={<span className={styles.counter}>{validationResult.signatures.length}</span>}
                    element={<ParticipantTab validationResult={validationResult} />}
                  />
                  <Tab label='History' element={<HistoryTab document={validationResult} />} />
                </Tabs>
              </Card>

              <Card
                size='medium'
                className={styles.certificateCard}
                header={{ title: 'Signature validity certificate' }}
              >
                <ul>
                  <li>
                    <Icon className={styles.certIcon} name='check_circle_outline' />
                    This certificate verifies that the document was signed using CorpoSign&apos;s immutable
                    blockchain technology and that its content has not been tampered with.
                  </li>
                  <li>
                    <Icon className={styles.certIcon} name='check_circle_outline' />
                    Use as proof in the event of a disagreement.
                  </li>
                  <li>
                    <Icon className={styles.certIcon} name='check_circle_outline' />
                    Full validation of hashed signatures on documents,
                    as well as timestamps and other essential information.
                  </li>
                </ul>
                <ul>
                  <li className={styles.infoCert}>
                    <Icon className={styles.certIcon} name='info_outline' />
                    Don&apos;t forget to obtain the Signature Validity Certificate.
                    Because CorpoSign does not preserve it, it is not available in your document list.
                  </li>
                </ul>
                <div className={styles.download}>
                  <Button
                    className={styles.downloadBtn}
                    children='Download certificate'
                    loading={certificateLoading}
                    onClick={() => fetchCertificate()}
                    size='medium'
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentPage;
