import React from 'react';
import { Skeleton } from 'antd';
import {
  Modal, Avatar, Form, Input, Divider, Icon, CopyButton, Button,
} from '@sis-lab/web-ui-components';

import { useForm } from 'react-hook-form';

import { ISignatureExtended } from 'types';
import { transformDate } from 'utils';
import viewOnJWT from '../../../../../../images/viewOnJWT.svg';
import styles from './SignatureDetailsModal.module.scss';

interface SignatureDetailsModalProps {
  visible: boolean,
  onClose: () => void,
  signature?: ISignatureExtended
}

const getWrapperClassName = (status: 'accept' | 'reject' | 'waiting') => {
  switch (status) {
    case 'accept': return styles.accepted;
    case 'reject': return styles.rejected;
    default: return '';
  }
};

const convertStatusString = (status: 'accept' | 'reject' | 'waiting') => {
  switch (status) {
    case 'accept': return 'Signed';
    case 'reject': return 'Rejected';
    default: return 'Waiting';
  }
};

const SignatureDetailsModal = (props: SignatureDetailsModalProps) => {
  const formHook = useForm();
  const {
    visible,
    signature,
    onClose,
  } = props;

  const navigateToJWTIO = () => {
    if (signature) {
      const jwtIO = `https://jwt.io/#debugger-io?token=${signature.owner.jws}&publicKey=${encodeURIComponent(signature.owner.certificate)}`;
      setTimeout(() => {
        window.open(jwtIO, '_blank', 'noopener,noreferrer');
      });
    }
  };

  return (
    <Modal title='Signature details' width={720} visible={visible} onCancel={onClose} footer={null}>
      { signature ? (
        <Form size='medium' onSubmit={() => null} formHook={formHook} className={styles.formContainer}>
          <div className={styles.generalInfoRow}>
            <Avatar width={80} initials={signature.owner.initials} />
            <div className={styles.generalInfoValues}>
              <Input
                disabled
                name='name'
                title='Full name'
                defaultValue={signature.owner.name}
              />
              <Input
                disabled
                name='status'
                title='Status'
                bodyClassName={getWrapperClassName(signature.status)}
                defaultValue={convertStatusString(signature.status)}
                prefix={(
                  <Icon
                    className={styles.icon}
                    name={signature.status === 'accept' ? 'check_circle_outline' : 'close_circle_outline'}
                  />
                )}
              />
              <Input
                disabled
                name='timestamp'
                title='Timestamp'
                defaultValue={transformDate(signature.created_at)}
              />
              <Input
                disabled
                name='notes'
                title='Notes'
                defaultValue={signature.notes || '—'}
              />
            </div>
          </div>
          <Divider size='small' />
          <div className={styles.companyValues}>
            <Input
              disabled
              name='position'
              title='Position'
              defaultValue={signature.owner.position}
            />
            <Input
              disabled
              name='email'
              title='Email address'
              defaultValue={signature.owner.email}
            />
            <Input
              disabled
              name='company_name'
              title='Company name'
              defaultValue={signature.owner.company_name}
            />
            <Input
              disabled
              name='company_id'
              title='Company ID'
              defaultValue={signature.owner.company_id}
            />
          </div>
          <Divider size='small' />
          <div className={styles.certificateValues}>
            <Input
              disabled
              name='certificate_id'
              title='Certificate ID'
              defaultValue={signature.owner.certificate_id}
            />
            <Input
              disabled
              name='authority_icd'
              title='Authority ICD'
              defaultValue={signature.owner.authority_icd}
            />
            <Input
              disabled
              name='ip_address'
              title='IP Address'
              defaultValue={signature.owner.ip_address}
            />
            <Input
              disabled
              name='coordinates'
              title='Coordinates'
              defaultValue={`${signature.owner.latitude}, ${signature.owner.latitude}`}
            />
          </div>
          <Divider size='small' />
          <div className={styles.footer}>
            <Button size='small' width='auto' className={styles.jwtButton} onClick={navigateToJWTIO}>
              <img src={viewOnJWT} alt='verify with JWT.IO' />
              <p>VIEW ON JWT.IO</p>
            </Button>
            <CopyButton
              className={[styles.button, styles.firstButton].join(' ')}
              copyText={signature.owner.jws}
              type='secondary'
            >
              <Icon className={styles.icon} name={'data_object' as 'arrow_left'} />
              <p>Copy JWS</p>
            </CopyButton>
            <CopyButton
              className={styles.button}
              copyText={signature.owner.certificate}
              type='secondary'
            >
              <Icon className={styles.icon} name={'verified' as 'arrow_left'} />
              <p>Copy Certificate</p>
            </CopyButton>
          </div>
        </Form>
      ) :
        <Skeleton active />}

    </Modal>
  );
};

export default SignatureDetailsModal;
