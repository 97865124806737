/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Avatar, Icon, TimelineItem } from '@sis-lab/web-ui-components';
import { IDocumentHistory, ISignatureExtended } from 'types';
import SignatureDetailsModal from '../DetailsModal/SignatureDetailsModal';
import styles from './AcceptRejectEvent.module.scss';
import { transformDate } from 'utils';

interface Props {
  event: IDocumentHistory
  signature: ISignatureExtended
}

const AcceptRejectEvent = ({ event, signature }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TimelineItem
      dot={<Avatar initials={event.actor.initials} width={40} />}
      title={event.actor.name}
      subtitle={event.actor.email}
      note={event.notes}
    >
      <div className={[styles.stamp, styles[event.action]].join(' ')}>
        <Icon
          className={styles.icon} 
          name={event.action === 'accept' ? 'check_circle_outline' : 'close_circle_outline'}
        />
        <div className={styles.header}>
          <p>{event.action === 'accept' ? 'Signed' : 'Rejected'}</p>
          <span onClick={() => setIsVisible(true)}>Details</span>
          <small>
            {transformDate(event.date)}
          </small>
        </div>
      </div>
      <SignatureDetailsModal signature={signature} visible={isVisible} onClose={() => setIsVisible(false)} />
    </TimelineItem>
  );
};

export default AcceptRejectEvent;
