import { AxiosResponse } from 'axios';
import { instance } from './instance';
import {
  IDocumentsResponse,
  ICreateDocumentRequest, IDocument, IQueryDocumentsRequest, IDocumentValidation,
} from '../../types';

export const validateDocument = (fingerprint: string): Promise<AxiosResponse<IDocumentValidation>> => instance
  .get<IDocumentValidation>(`/validate/${fingerprint}`);

export const getDocument = (fingerprint: string): Promise<AxiosResponse<IDocument>> => instance
  .get<IDocument>(`/documents/${fingerprint}`);

export const getDocuments = (bookmark = ''): Promise<AxiosResponse<IDocumentsResponse>> => instance
  .get<IDocumentsResponse>(`/documents?bookmark=${bookmark}`);

export const createDocument = (request: ICreateDocumentRequest): Promise<AxiosResponse<IDocument>> => instance
  .post<IDocument>('/documents', request);

export const queryDocuments = (request: IQueryDocumentsRequest): Promise<AxiosResponse<IDocument[]>> => instance
  .get<IDocument[]>('/documents', { params: request });

export const getDocumentCertificate = (fingerprint: string): Promise<AxiosResponse<string>> => instance
  .get(`/certificates/document/${fingerprint}`, { responseType: 'arraybuffer' });
