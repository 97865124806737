import React from 'react';

import {
  Avatar, Icon, Label, Caption
} from '@sis-lab/web-ui-components';

import { ISignatureExtended } from 'types';
import { transformDate } from 'utils';
import styles from './ParticipantCard.module.scss';

interface ParticipantCardActiveProps {
  signature: ISignatureExtended
}

const Active = ({ signature }: ParticipantCardActiveProps) => (
  <div className={[styles.active, styles[signature.status]].join(' ')}>
    <div className={styles.signee}>
      <Avatar width={40} initials={signature.owner.initials} />
      <div className={styles.signeeDetails}>
        <Label>{signature.owner.name}</Label>
        <Caption small>{signature.owner.email}</Caption>
      </div>
    </div>
    <div className={[styles.stamp, styles[signature.status]].join(' ')}>
      <Label className={styles.status}>
        <Icon
          className={styles.icon}
          name={signature.status === 'accept' ? 'check_circle_outline' : 'close_circle_outline'}
        />
        {signature.status === 'accept' ? 'Signed' : 'Rejected'}
      </Label>
      <Caption className={styles.date} small>{transformDate(signature.created_at)}</Caption>
    </div>
  </div>
);

const ParticipantCard = {
  Active,
};

export default ParticipantCard;
