const {
  REACT_APP_PROJECT_COMMIT_DATE,
  REACT_APP_PROJECT_COMMIT_SHORT_SHA,
  REACT_APP_STORAGE_API_URL,
  REACT_APP_API_URL,
} = process.env;

const { protocol, hostname } = window.location;

export default {
  projectCommitDate: REACT_APP_PROJECT_COMMIT_DATE || '',
  projectCommitShortSHA: REACT_APP_PROJECT_COMMIT_SHORT_SHA || '',
  apiUrl: REACT_APP_API_URL || `${protocol}//api.${hostname}/contract/v1`,
  storageApiUrl: REACT_APP_STORAGE_API_URL || `${protocol}//api.${hostname}/storage/v1`,
};
