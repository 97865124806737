import webApi from 'apis/corposign';

import { getStoredDocumentByUri } from 'apis/corposign-storage';

import { IDocument, IDocumentValidation } from 'types';

export const getDocumentService = async (documentId: string) => {
  const documentResponse = await webApi.getDocument(documentId);
  const document: IDocument = documentResponse.data;
  return document;
};

export const validateDocumentService = async (fingerprint: string) => {
  const validateDocumentResponse = await webApi.validateDocument(fingerprint);
  const documentValidation: IDocumentValidation = validateDocumentResponse.data;
  return documentValidation;
};

export const getDocumentContentService = async (documentURL:string) => {
  const response = await getStoredDocumentByUri(documentURL);
  return response.data;
};

export const getDocumentsService = async (oldBookmark: string, oldDocuments: any) => {
  const documentsResponse = await webApi.getDocuments(oldBookmark);
  const {
    data: {
      bookmark, documents, page_size: pageSize, count,
    },
  } = documentsResponse;
  return {
    documents: bookmark ? [...oldDocuments, ...documents] : documents,
    bookmark,
    pageSize,
    count,
  };
};

export const getDocumentCertificateService = async (fingerprint: string): Promise<[Blob, string]> => {
  const documentCertificateResponse = await webApi.getDocumentCertificate(fingerprint);
  const filetype = 'pdf';
  const filename = fingerprint;
  const blob = new Blob(
    [documentCertificateResponse.data],
    { type: documentCertificateResponse.headers['content-type'] },
  );
  return [blob, filetype ? `${filename}.${filetype}` : ''];
};
