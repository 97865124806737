import React from 'react';
import { Avatar, Timeline, TimelineItem } from '@sis-lab/web-ui-components';
import { IDocumentHistory, IDocumentValidation, ISignatureExtended } from 'types';
import AcceptRejectEvent from './component/AcceptRejectEvent/AcceptRejectEvent';
import CreateDocEvent from './component/CreateDocEvent/CreateDocEvent';
import styles from './HistoryTab.module.scss';

interface Props {
  document: IDocumentValidation
}

const findSignature = (event: IDocumentHistory, signatures: ISignatureExtended[]) => signatures.find(
  (signature) => signature.owner.identity_id === event.actor.identity_id,
) as ISignatureExtended;

const eventMap = (event: IDocumentHistory, signatures: ISignatureExtended[]) => {
  switch (event.action) {
    case 'accept':
    case 'reject':
      return <AcceptRejectEvent event={event} signature={findSignature(event, signatures)} />;
    case 'create':
      return <CreateDocEvent event={event} />;
    default:
      return (
        <TimelineItem
          dot={<Avatar initials={event.actor.initials} width={40} />}
          title={event.actor.name}
          subtitle={event.actor.email}
          note={event.notes}
          children={event.action}
        />
      );
  }
};

const HistoryTab = ({ document }: Props) => (
  <div className={styles.history}>
    <Timeline>
      {document.history.map((event) => eventMap(event, document.signatures))}
    </Timeline>
  </div>
);

export default HistoryTab;
