import React from 'react';
import { Avatar, Icon, TimelineItem } from '@sis-lab/web-ui-components';
import { IDocumentHistory } from 'types';
import styles from './CreateDocEvent.module.scss';
import { transformDate } from 'utils';

interface Props {
  event: IDocumentHistory
}

const CreateDocEvent = ({ event }: Props) => (
  <TimelineItem
    dot={<Avatar initials={event.actor.initials} width={40} />}
    title={event.actor.name}
    subtitle={event.actor.email}
    note={event.notes}
  >
    <div className={[styles.stamp, styles[event.action]].join(' ')}>
      <Icon className={styles.icon} name={'post_add' as 'document'} />
      <div className={styles.header}>
        <p>Created document</p>
        <small>
          {transformDate(event.date)}
        </small>
      </div>
    </div>
  </TimelineItem>
);

export default CreateDocEvent;
