import {
  instance,
  setAccessToken,
} from './instance';

import {
  getDocument,
  getDocuments,
  createDocument,
  queryDocuments,
  validateDocument,
  getDocumentCertificate,
} from './documents';


export default {
  getDocument,
  getDocuments,
  createDocument,
  queryDocuments,
  validateDocument,
  getDocumentCertificate,
  instance,
  setAccessToken,
};
